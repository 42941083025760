import {IWixAPI, ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {isRtlLanguage, EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, Store, PreloadedState} from 'redux'
import {createEventHandler} from '@wix/yoshi-flow-editor/tpa-settings'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {Tabs} from '../../commons/enums'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getCurrentMemberId, getMembersAPI} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getLocale, isSSR} from '../../commons/utils/wix-code-api'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {setActiveElement} from '../../commons/actions/focus-handler'
import {getEventList} from './Widget/selectors/events'
import {
  closeAllEvents,
  getEvents,
  seeOtherEvents,
  setTab,
  shareEvent,
  toggleEventDetails,
} from './Widget/actions/events'
import {internalNavigate, navigateToDetailsPage, navigateToNoUpcomingEventsUrl} from './Widget/actions/navigation'
import {cancelRsvp} from './Widget/actions/rsvp'
import {updateSettings} from './Widget/actions/sdk'
import {downloadTicketsAction, getMyTickets} from './Widget/actions/tickets'
import {resetToLiveView, showNoEvents, showDemoEvents} from './Widget/actions/view'
import * as eventsUouEvents from './Widget/bi/uou-bi-events-map'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {Actions, MemberPageState, StoreExtraArgs} from './Widget/types/state'
import {Api} from './Widget/utils/api'
import {getComponentData, updateComponent} from './Widget/actions/component'
import {SettingsEvents, MembersSettingsEventsKeys, MembersSettingsEventsActions} from './constants'

export const createMembersPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const membersPageEditorOOIEnabled = controllerParams.flowAPI.experiments.enabled(
    'specs.events.ui.MembersPageEditorOOI',
  )
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )

  return {
    pageReady: () => pageReady({controllerParams, membersPageEditorOOIEnabled, componentEventHandler}),
    updateConfig: (_, newConfig) => {
      if (membersPageEditorOOIEnabled) {
        componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
      }
    },
  }
}

interface PageReadyParams {
  controllerParams: ControllerParams
  membersPageEditorOOIEnabled: boolean
  componentEventHandler: ReturnType<typeof createEventHandler>
}

const pageReady = async ({controllerParams, membersPageEditorOOIEnabled, componentEventHandler}: PageReadyParams) => {
  const controllerConfig = controllerParams.controllerConfig
  const {appParams, setProps, wixCodeApi, config} = controllerConfig
  const language = getLanguage(wixCodeApi)
  const locale = getLocale(wixCodeApi)
  const experiments = controllerParams.flowAPI.experiments.all()

  const [viewedSiteMemberId, pageUrl] = await Promise.all([
    getSiteMemberId({controllerConfig, membersPageEditorOOIEnabled}),
    getPageUrl(controllerConfig),
  ])

  const store = createStore(controllerParams, experiments, viewedSiteMemberId, pageUrl)
  const actions = exportedActions(store)

  await Promise.all([
    locale ? actions.getEvents(Tabs.UPCOMING, locale) : null,
    actions.getComponentData({
      responsive: isResponsiveEditor(config),
      language,
      locale,
      compId: controllerConfig.compId,
      viewMode: wixCodeApi.window.viewMode.toLowerCase(),
    }),
  ])

  await store.dispatch(setBaseEnvironment() as any)

  if (!locale) {
    // after component/siteSettings fetch and setBaseEnvironment there is definitely correct locale
    // for Editor, where wixCodeApi does not contain locale.
    await actions.getEvents(Tabs.UPCOMING)
  }

  const state = store.getState()

  setProps({
    state,
    actions,
    cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
    isRTL: isRtlLanguage(language),
  })

  subscribeToStateChanges(controllerConfig, store)

  const ssr = isSSR(wixCodeApi)

  if (!ssr) {
    actions.getEvents(Tabs.PAST)
  }

  if (membersPageEditorOOIEnabled) {
    componentEventHandler.on(
      MembersSettingsEventsKeys.ForceView,
      (forceMembersPageUIState: MembersSettingsEventsActions) => {
        if (forceMembersPageUIState === MembersSettingsEventsActions.OpenDefault) {
          store.dispatch(showNoEvents(false))
          store.dispatch(showDemoEvents())
          return store.dispatch(closeAllEvents())
        }

        if (forceMembersPageUIState === MembersSettingsEventsActions.OpenNoEvents) {
          return store.dispatch(showNoEvents(true))
        }

        if (forceMembersPageUIState === MembersSettingsEventsActions.OpenDetails) {
          store.dispatch(showNoEvents(false))
          const events = getEventList(store.getState())
          events.slice(0, 2).forEach(event => store.dispatch(toggleEventDetails(event) as any))
          return
        }
      },
    )
  }
}

const createStore = (controllerParams: ControllerParams, experiments: ExperimentsBag, viewedSiteMemberId, pageUrl) => {
  const serverApi = new Api(controllerParams)
  const controller = controllerParams.controllerConfig

  const initialData: PreloadedState<MemberPageState> = {
    experiments,
    multilingual: getMultilingualInitialState(controller.wixCodeApi),
    user: {
      currentUserId: controller.wixCodeApi.user.currentUser.id,
      viewedSiteMemberId,
    },
  } as PreloadedState<MemberPageState>
  const middleware = [
    createUouBiMiddlewareWithBiParams(
      {
        wixCodeApi: controller.wixCodeApi,
        platformAPIs: controller.platformAPIs,
        appParams: controller.appParams,
        compId: controller.compId,
        user: {
          uid: controller.wixCodeApi.user.currentUser.id,
        },
      },
      eventsUouEvents,
    ),
    datesMiddleware,
  ]

  return createReduxStore<MemberPageState, StoreExtraArgs>({
    initialData,
    extraArguments: {
      wixCodeApi: controller.wixCodeApi,
      serverApi,
      compId: controller.compId,
      baseUrl: controller.appParams.baseUrls.baseUrl,
      pageUrl,
      flowAPI: controllerParams.flowAPI,
    },
    reducers,
    middleware,
  })
}

interface GetSiteMemberIdParams {
  controllerConfig: ControllerParams['controllerConfig']
  membersPageEditorOOIEnabled: boolean
}

const getSiteMemberId = ({controllerConfig, membersPageEditorOOIEnabled}: GetSiteMemberIdParams) => {
  const {wixCodeApi, appParams} = controllerConfig

  if (appParams.baseUrls.siteMemberId) {
    return appParams.baseUrls.siteMemberId
  }

  if (membersPageEditorOOIEnabled && wixCodeApi.window.viewMode === 'Editor') {
    return handleGetCurrentMemberId(wixCodeApi)
  }

  return getViewedUserId(wixCodeApi)
}

const getViewedUserId = async (wixCodeApi: IWixAPI) => {
  try {
    const membersApi = await getMembersAPI(wixCodeApi)
    return await membersApi.getViewedUser()
  } catch (e) {
    return handleGetCurrentMemberId(wixCodeApi)
  }
}

const handleGetCurrentMemberId = (wixCodeApi: IWixAPI) => {
  const currentMemberId = getCurrentMemberId(wixCodeApi)

  if (currentMemberId) {
    return currentMemberId
  }

  throw new Error('There are no members area installed on this site')
}

const getPageUrl = async ({wixCodeApi}: ControllerParams['controllerConfig']): Promise<string> => {
  const pageUrl = await wixCodeApi.site.getSectionUrl({sectionId: 'events', appDefinitionId: EVENTS_APP_ID})
  return pageUrl?.url ?? null
}

const exportedActions = (store: Store): Actions => {
  const dispatchActions = {
    getComponentData,
    getEvents,
    toggleEventDetails,
    cancelRsvp,
    shareEvent,
    navigateToDetailsPage,
    internalNavigate,
    updateSettings,
    updateComponent,
    setTab,
    resetToLiveView,
    downloadTicketsAction,
    closeAllEvents,
    seeOtherEvents,
    setFormFactor,
    navigateToNoUpcomingEventsUrl,
    getMyTickets,
    setActiveElement,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }

  return actions
}

export default createMembersPageController
